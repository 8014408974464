













































































































import Vue from 'vue'
import {
  BCard, BRow, BCol,
  BButton, BForm, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { appAdminProfile } from '@/store/modules/app-admin-profile'
import { permissionSubjects } from '@/libs/acl/constants'

export default Vue.extend({
  name: 'AdminViewInfo',
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      isMailable: false,
      isActive: false,
      permissionSubjects,
    }
  },
  computed: {
    userData() {
      return appAdminProfile.getters.getUserData
    },
    authUserData() {
      return appAdminProfile.getters.getAuthUserData
    },
  },
  watch: {
    userData() {
      this.selectedSchoolData()
    },
  },
  methods: {
    selectedSchoolData() {
      const currentSchool = this.userData.schools.find((school:any) => school.id === this.authUserData.current_school_id)
      this.isMailable = currentSchool?.is_mailable || false
      this.isActive = currentSchool?.is_active || false
    },
    async updUserInfo() {
      await appAdminProfile.actions.updUserInfo()
      this.$router.back()
    },
    handleForm() {
      this.userData.isMailable = this.isMailable
      this.userData.isActive = this.isActive
      this.updUserInfo()
    },
  },
})
