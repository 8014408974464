import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { getAdmin, updateAdmin } from '@/api/admins'
import { AdminProfileState } from './types'
import { IAdminData } from '@/views/admin/admins/types'

// eslint-disable-next-line import/prefer-default-export
export const appAdminProfile = createModule(
  'appAdminProfile',
  {
    namespaced: true,
    state: {
      userData: {},
      authUserData: JSON.parse(localStorage.getItem('userData') || '{}'),
    } as AdminProfileState,
    getters: {
      getUserData: state => state.userData,
      getAuthUserData: state => state.authUserData,
    },
    mutations: {
      SET_USER_DATA(state, val: IAdminData) {
        state.userData = val
      },
    },
    actions: {
      async setUserData() {
        try {
          const response = await getAdmin(router.currentRoute.params.id)
          appAdminProfile.mutations.SET_USER_DATA(response.data.data)
        } catch (error: any) {
          if (error.response.status === 404) {
            appAdminProfile.mutations.SET_USER_DATA({} as IAdminData)
          }
        }
      },
      async updUserInfo({ state }) {
        try {
          const preparedData = {
            id: state.userData.id,
            first_name: state.userData.first_name,
            last_name: state.userData.last_name,
            email: state.userData.email,
            phone: state.userData.phone,
            schools_data: {
              id: state.authUserData.current_school_id,
              is_mailable: state.userData.isMailable,
              is_active: state.userData.isActive,
            },
          }
          await updateAdmin(preparedData)
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'User was successfully updated',
              icon: 'ThumbsUpIcon',
              variant: 'info',
            },
          })
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error updating admin',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      },
    },
  },
)

appAdminProfile.register(store)
