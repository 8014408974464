















































































import Vue from 'vue'
import {
  BCard, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { appAdminProfile } from '@/store/modules/app-admin-profile'

export default Vue.extend({
  name: 'AdminViewInfoCard',
  components: {
    BCard, BRow, BCol, BAvatar,
  },
  computed: {
    userData() {
      return appAdminProfile.getters.getUserData
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
})
