














































import Vue from 'vue'

import {
  BRow, BCol, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import AdminViewInfoCard from '@/views/admin/admins/admin-profile/AdminViewInfoCard.vue'
import AdminViewInfo from '@/views/admin/admins/admin-profile/AdminViewInfo.vue'
import { appAdminProfile } from '@/store/modules/app-admin-profile'
import { IAdminData } from '../types'

export default Vue.extend({
  name: 'AdminProfile',
  components: {
    BRow,
    BCol,
    BCard,
    BAlert,
    BLink,

    AdminViewInfo,
    AdminViewInfoCard,
  },
  computed: {
    userData() {
      return appAdminProfile.getters.getUserData
    },
    authUserData() {
      return appAdminProfile.getters.getAuthUserData
    },
  },
  async created() {
    await this.setUserData()
  },
  beforeDestroy() {
    appAdminProfile.mutations.SET_USER_DATA({} as IAdminData)
  },
  methods: {
    async setUserData() {
      await appAdminProfile.actions.setUserData()
    },
  },
})
